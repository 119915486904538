<template>
<header>
  <div class="logo">Score Board</div>
  <div class="page-name" @click="changePageBoard">{{ pageName }}</div>
</header>
  <div class="contents">
    <board v-if="page == 'board'" @score="changePageScore" />
    <score v-if="page == 'score'" :board="board" />
  </div>
</template>

<script>
import Board from "./components/Board.vue";
import Score from "./components/Score.vue";

export default {
  name: "App",
  components: {
    Board,
    Score,
  },
  data: function () {
    return {
      page: "board",
      pageName: "ボード選択",
      board: 0,
    };
  },
  methods:{
    changePageScore: function(index){
      this.page = "score";
      this.pageName = "スコアボード";
      this.board = index;
    },
    changePageBoard: function(){
      this.page = "board";
      this.pageName = "ボード選択";
    }
  }
};
</script>

<style>
@import "./css/normalize.css";
@import "./css/skeleton.css";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');

body{
  font-family: 'Noto Sans JP', sans-serif;
}
header{
  background: #4E3620;
  color: white;
  width: 100%;
  height: 50px;
  padding: .5em;
}
.logo{
  font-size: 6px;
}
.page-name{
  font-size: 18px;
  cursor: pointer;
}
.contents{
  margin-top: 1em;
  padding: 1em;
}
</style>
