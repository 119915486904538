<template>
  <div>
    <div class="card" @click="modal = !modal">
      <fa class="plus-icon" icon="plus-circle" />
      <div>新規作成</div>
    </div>

    <div class="kizon">
      <div class="card board" v-for="(board, index) in boardList" :key="index">
        <div class="board-name" @click="$emit('score', index)">{{ board.name }}</div>
        <div class="del-button" @click="delBoard(index)">
          <fa icon="trash-alt" />
        </div>
      </div>
    </div>

    <div v-if="modal" class="back" @click="modal = !modal"></div>
    <div v-if="modal" class="modal">
      <label class="board-name-input"
        >ボード名
        <input type="text" v-model="boardName" />
      </label>
      <button class="create-button" @click="createBoard()">作成</button>
      <button @click="modal = !modal">キャンセル</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Board",
  data: function () {
    return {
      boardList: [],
      modal: false,
      boardName: "",
    };
  },
  mounted: function () {
    this.boardList = JSON.parse(localStorage.getItem("board"));
  },
  methods: {
    createBoard: function () {
      let board = {
        name: this.boardName,
        time: 1,
        member: [
          {
            name: "",
            image: "",
            score: [0],
          },
        ],
      };
      if (this.boardList) {
        this.boardList.push(board);
      } else {
        this.boardList = [board];
      }
      localStorage.setItem("board", JSON.stringify(this.boardList));
      this.modal = false;
    },
    delBoard: function (index) {
      this.boardList.splice(index, 1);
      localStorage.setItem("board", JSON.stringify(this.boardList));
    },
  },
};
</script>

<style scoped>
.card {
  width: 100px;
  height: 100px;
  margin-right: 3em;
  margin-top: 2em;
  background: #f8f4e1;
  border-radius: 10px;
  text-align: center;
  border: 2px dashed #897853;
  box-shadow: 0 0 0 4px #f8f4e1, 1px 2px 5px 2px rgba(0, 0, 0, 0.5);
  float: left;
  top: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.card:hover {
  top: -4px;
}
.card:active {
  top: 4px;
}
.plus-icon {
  font-size: 50px;
  color: #4e3620;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.kizon{
  min-height: 80px;
  float:left;
}
.board {
  margin-right: 1.5em;
  padding-top: 20px;
  height: 80px;
  background: #e8faf0;
  border: 2px dashed #897853;
  box-shadow: 0 0 0 4px #e8faf0, 1px 2px 5px 2px rgba(0, 0, 0, 0.5);
}
.board-name {
  color: #4ac07f;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  height: 80px;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  overflow: hidden;
}
.del-button {
  position: absolute;
  top: -20px;
  right: -15px;
  width: 40px;
  height: 38px;
  border-radius: 50%;
  background: #897853;
  font-size: 20px;
  color: white;
  text-align: center;
  padding-top: 2px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.back {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.modal {
  width: 300px;
  height: 200px;
  padding: 10px;
  background: #f8f4e1;
  border: solid 2px #4e3620;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.board-name-input{
  margin-top: 50px;
}
.create-button {
  margin: 20px 30px 0 30px;
  background: #4e3620;
  color: white;
}
</style>
