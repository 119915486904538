<template>
  <div class="hello">
    <h2>{{ this.score.name }}</h2>
    <table v-for="(member, index) in this.score.member" :key="index">
      <tr>
        <th class="name">
          <input
            @change="changeName"
            class="name-input"
            type="text"
            v-model="member.name"
          />
        </th>
        <th v-for="n in this.score.time" :key="n">
          <div class="mini-button" @click="addScore(index, n)">
            <fa icon="plus-circle" />
          </div>
          <div class="mini-button black">{{ n }}</div>
          <div class="mini-button" @click="removeScore(index, n)">
            <fa icon="minus-circle" />
          </div>
        </th>
        <th>合計</th>
      </tr>

      <tr>
        <td>
          <label class="file-input">
            <fa icon="plus" />
            <input
              type="file"
              style="display: none"
              accept="image/png, image/jpeg"
              @change="uploadFile($event, index)"
            /> </label
          ><img v-if="member.image" :src="member.image" />
        </td>
        <td v-for="(score, index) in member.score" :key="index">
          {{ score }}
        </td>
        <td>
          {{
            member.score.reduce(function (prev, current, i, arr) {
              return prev + current;
            })
          }}
        </td>
      </tr>
    </table>
    <div class="add-button">
      <fa class="add" @click="addTime" icon="plus" />回数<fa
        class="add"
        @click="removeTime"
        icon="minus"
      />
    </div>
    <div class="add-button">
      <fa class="add" @click="addMember" icon="plus" />メンバー<fa
        class="add"
        @click="removeMember"
        icon="minus"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Score",
  props: {
    board: {
      type: Number,
    },
  },
  data: function () {
    return {
      score: {},
      modal: false,
      boardName: "",
      local: [],
    };
  },
  mounted() {
    this.local = JSON.parse(localStorage.getItem("board"));
    console.log(this.local[this.board]);
    this.score = this.local[this.board];
  },
  methods: {
    changeName: function () {
      localStorage.setItem("board", JSON.stringify(this.local));
    },
    addScore: function (index, n) {
      this.score.member[index].score[n - 1] += 1;
      this.local[this.board] = this.score;
      localStorage.setItem("board", JSON.stringify(this.local));
    },
    removeScore: function (index, n) {
      if(this.score.member[index].score[n - 1] > 0){
      this.score.member[index].score[n - 1] -= 1;
      this.local[this.board] = this.score;
      localStorage.setItem("board", JSON.stringify(this.local));
      }else{
        console.log("no")
      }
    },
    addTime: function () {
      this.score.time += 1;
      const time = this.score.member.length;
      for (let i = 0; i < time; i++) {
        console.log(this.score.member[i].score);
        this.score.member[i].score.push(0);
      }
      this.local[this.board] = this.score;
      localStorage.setItem("board", JSON.stringify(this.local));
    },
    removeTime: function () {
      if(this.score.time){
      this.score.time -= 1;
      const time = this.score.member.length;
      for (let i = 0; i < time; i++) {
        console.log(this.score.time);
        this.score.member[i].score.splice(this.score.time, 1);
      }
      this.local[this.board] = this.score;
      localStorage.setItem("board", JSON.stringify(this.local));
      }
    },
    addMember: function () {
      const mem = {
        name: "",
        image: "",
        score: [],
      };
      for (let i = 0; i < this.score.time; i++) {
        mem.score.push(0);
      }
      this.score.member.push(mem);
      this.local[this.board] = this.score;
      localStorage.setItem("board", JSON.stringify(this.local));
    },
    removeMember: function () {
      const mem = this.score.member.length;
      this.score.member.splice(mem - 1, 1);
      this.local[this.board] = this.score;
      localStorage.setItem("board", JSON.stringify(this.local));
    },
    uploadFile: function (event, index) {
      const reader = new FileReader();

      const file = event.target.files[0];

      if(file){
        reader.readAsDataURL(file)
      }else{
        this.url = ''
      }
      reader.onload = () => {
        let url = reader.result;
        this.score.member[index].image = url;
        localStorage.setItem("board", JSON.stringify(this.local));
      }
      console.log(index);
    },
  },
};
</script>

<style scoped>
th {
  height: 20px;
  background: #f8f4e1;
  width: 85px;
  border: solid 1px gray;
  text-align: center;
  padding: 0 5px;
}
th.name {
  width: 120px;
}
th input{
  height: 2em;
  padding: 0;
  margin-top: 10px;
}
td {
  height: 65px;
  border: solid 1px gray;
  text-align: center;
  font-size: 40px;
  padding: 0;
}
.mini-button {
  font-size: 20px;
  float: left;
  padding: 0 5px;
  color: #ece7cd;
  cursor: pointer;
}
.black {
  color: black;
}
.add-button {
  height: 25px;
  width: 150px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #ebfbf3;
  font-size: 15px;
  text-align: center;
  float: left;
  margin-right: 1.5em;
  color: #b4e7cd;
}
.add {
  margin: 0 0.7em;
  cursor: pointer;
}
.name-input {
  width: 8em;
  background: transparent;
  border: none;
  cursor: pointer;
}
.file-input {
  font-size: 10px;
  text-align: left;
  position: absolute;
  cursor: pointer;
  color: #acacac;
}
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
